import React, { createContext, useMemo, memo, useCallback, useEffect, useState, useRef } from "react"
import { SSE } from "utils/SSE"
import { SSECallCache } from "martti-agent-services" //TODO multi-call: remove after multi-call is resolved
import { logger } from "utils/logger"
import { config } from "utils/configService"
import { dataService } from "utils/dataService"

import { useAuth } from "contexts/Auth"
import { useLoading } from "@cloudbreakus/ui-components"
import { useNavigate } from "hooks/useNavigate"
import { AVAILABLE, ON_BREAK, IN_QA_MEETING, usePresence } from "contexts/Presence"
import { useAlertMessage } from "hooks/useAlertMessage"
import { createContextHook } from "utils/contextHelpers"
import { parseTaskCallData } from "./utils/parseCallData"

import { usePlayer } from "./usePlayer"
import { CallFormDataProvider } from "./Form"
import { CallSegmentsDataProvider } from "./Segments"
import { CallHandlingDataProvider } from "./Handling"
import { useInitialCallStatus } from "./useInitialCallStatus"
import { useCallNotifications } from "./useNotifications"
import { useUpdateDetailsOnRing } from "./useUpdateDetailsOnRing"
import { useCallParticipants } from "./useCallParticipants"
import { useCallConnecting } from "./useCallConnecting"
import useCallAttributes from "./useCallAttributes"
import { callManager } from "./CallManager"

import useStore from "store"
import {
  selectAnsweredCall,
  selectRingingCall,
  selectHasAVIssues,
  selectExpectingAnswer,
  selectSetDialHistory,
} from "store/selectors/call"
import { usePureCallback } from "hooks/usePureCallback"
import { useReport } from "hooks/useReport"

const { HttpErrors } = require("@cloudbreakus/network-utilities")

const CallContext = createContext()

export const _CallProvider = ({ children }) => {
  const { set: setPresence, afterCall: presenceAfterCall, setAfterCall: setPresenceAfterCall } = usePresence()
  const { userData, isAuthenticated, SSEopen } = useAuth()
  const { showLoading, hideLoading } = useLoading()
  const { showWarnToast, showErrorToast, showCustomToast } = useAlertMessage()
  const setNavigate = useNavigate()

  const [ringingCall, setRingingCall] = useStore(selectRingingCall)
  const setDialHistory = useStore(selectSetDialHistory)
  const [nmsParticipantId, setNmsParticipantId] = useState(null)
  const [_answeredCall, setAnsweredCall] = useStore(selectAnsweredCall)
  const subscribedRef = useRef(false)
  const [exitConfirmation, setExitConfirmation] = useState(true)
  const [disconnected, setDisconnected] = useState(false) // after video streaming ends
  const [callerHungUp, setCallerHungUp] = useState(false)
  const [expectingAnswer, setExpectingAnswer] = useStore(selectExpectingAnswer)
  const expectedInteractionIdRef = useRef(null)
  const [answerFailedToast, setAnswerFailedToast] = useState(null)
  const [supervisorCall, setSupervisorCall] = useState(null)
  const [, setHasAVIssues] = useStore(selectHasAVIssues)

  const answeredCall = useMemo(() => {
    if (!_answeredCall) {
      return null
    }
    const twilioAudioCall = !_answeredCall?.interaction?.mediaServer
    if (twilioAudioCall) {
      return _answeredCall
    }
    if (!nmsParticipantId) {
      return null
    }
    return {
      ..._answeredCall,
      nmsParticipantId,
    }
  }, [_answeredCall, nmsParticipantId])

  const taskBelongsToMe = useMemo(() => (answeredCall ? !answeredCall?._action : null), [answeredCall])

  useCallAttributes({ answeredCall, ringingCall })

  const { report, callReported, callReporting } = useReport(
    answeredCall?.interaction?.id,
    answeredCall?.interaction?.name
  )

  const answerCall = useCallback(
    async (taskId, reservationId) => {
      try {
        showLoading()
        logger.info(`Attempting to answer call with task ID ${taskId} & reservation ID ${reservationId}`)
        const { data } = await dataService.Calls.answer({ taskId, reservationId })
        setNmsParticipantId(data?.participantId)
        logger.info(`Call with task ID ${taskId} & reservation ID ${reservationId} has been answered`, data)
      } catch (err) {
        logger.error(`Unable to accept call (id: ${taskId} & reservation ID ${reservationId})`, err)
        const message =
          err instanceof HttpErrors.ThrottledNetworkError
            ? "Too many requests. Please try again later."
            : "Something went wrong answering your call."
        const dismiss = showCustomToast({
          message,
          level: "warn",
          onRetry: () => answerCall(taskId, reservationId),
        })
        setAnswerFailedToast({ dismiss })
      } finally {
        hideLoading()
      }
    },
    [showLoading, showCustomToast, hideLoading]
  )

  const _closeCall = useCallback(
    async ({ taskId, nextPresence }) => {
      try {
        await dataService.Calls.close({ taskId, nextPresence })
        logger.debug(`Call task has been terminated (id: ${taskId})`)
      } catch (err) {
        logger.error(`Termination of task has failed (id: ${taskId})`, err)
        showCustomToast({
          message: "Something went wrong while terminating your call.",
          level: "error",
          onRetry: () => _closeCall({ taskId, nextPresence }),
          closeable: true,
        })
      }
    },
    [showCustomToast]
  )

  const initializeCallScreen = useCallback(() => {
    setDisconnected(false)
    setExitConfirmation(false)
    setRingingCall(null)
    setDialHistory([])
    setNavigate("/current-call")
  }, [setNavigate, setRingingCall, setDialHistory])

  //TODO multi-call: remove after multi-call is resolved
  const iterateOverTasks = useCallback(async (calls) => {
    for (const call of calls) {
      try {
        logger.info(`Attempting to answer multi-call ${call.id}`)
        const { data } = await dataService.Calls.answer({ taskId: call.id })
        setNmsParticipantId(data?.participantId)
        logger.info(`Multi-Call with task ID ${call.id} has been answered`, data)
        return true
      } catch (err) {
        SSECallCache.ignoreTask(call?.id)
        logger.error(`Unable to accept multi-call (id: ${call?.id})`, err, call)
        const artificialDelay = config.get("multitask.artificialDelaySeconds", 0.2)
        await new Promise((resolve) => setTimeout(resolve, artificialDelay * 1_000))
        continue
      }
    }
  }, [])

  //TODO multi-call: remove after multi-call is resolved
  const multiCallAccept = useCallback(
    async (calls) => {
      showLoading()
      const itWorked = await iterateOverTasks(calls)
      hideLoading()
      if (itWorked) {
        setExpectingAnswer(true)
        initializeCallScreen()
      }
      return
    },
    [hideLoading, initializeCallScreen, iterateOverTasks, setExpectingAnswer, showLoading]
  )

  //TODO multi-call: remove after multi-call is resolved
  const handleNewSSEMultiCall = useCallback(
    (calls) => {
      //Note: This ignores the case where a supervisor self-assigns a call and gets multiple
      setRingingCall({
        interaction: {
          channel: "Unknown",
        },
        tasks: calls,
      })
      callManager.onCallRing()
    },
    [setRingingCall]
  )

  //TODO multi-call: remove after multi-call is resolved
  const onAccept = useCallback(
    async (call) => (call?.tasks ? multiCallAccept(call?.tasks) : callManager.answerCall(call)),
    [multiCallAccept]
  )

  const acceptCall = useCallback(
    (call) => {
      setExpectingAnswer(true)
      initializeCallScreen()
      answerCall(call.taskId, call.reservationId)
    },
    [answerCall, initializeCallScreen, setExpectingAnswer]
  )

  const handleRingingCall = usePureCallback((call) => {
    if (call?.interaction?.id === expectedInteractionIdRef.current) {
      answerCall(call.taskId, call.reservationId)
    } else {
      setRingingCall(call)
    }
  })

  const handleNewSSECall = usePureCallback((call) => {
    handleRingingCall(parseTaskCallData(call, userData.userId))
    callManager.onCallRing()
  })

  const handleCallDropped = useCallback(() => {
    setRingingCall(null)
    setCallerHungUp(true)
    callManager.onCallEnd()
  }, [setRingingCall])

  const handleAnsweredCall = usePureCallback(
    (call) => {
      const parsedCall = parseTaskCallData(call, userData.userId)
      logger.info(`Call with id ${parsedCall?.taskId} successfully taken`, parsedCall)
      setAnsweredCall(() => parsedCall)
      callManager.onCallStart()
    },
    [setAnsweredCall, userData?.userId]
  )

  const listenIn = useCallback(
    async (task) => {
      try {
        setSupervisorCall(true)
        logger.debug("Listening in on task", task)
        const answeredCall = parseTaskCallData(task, userData?.userId, "LISTEN")
        if (answeredCall.interaction.mediaServer === "NMS") {
          const { data } = await dataService.Interactions.listenIn({
            agentId: userData?.userId,
            taskId: answeredCall.taskId,
            languageId: answeredCall.language.id,
            interactionId: answeredCall.interaction.id,
          })
          logger.debug("Listen-in request succeeded", data)
          setNmsParticipantId(data?.participantId)
        }
        setPresence(IN_QA_MEETING)
        initializeCallScreen()
        setAnsweredCall(answeredCall)
      } catch (err) {
        setSupervisorCall(null)
        logger.error("An error has occurred while attempting to listen in to task", err)
        showErrorToast("An error has occurred while attempting to listen in to task.  Refresh the list and try again", {
          autoClose: true,
        })
      }
    },
    [initializeCallScreen, setAnsweredCall, userData?.userId, setPresence, showErrorToast]
  )

  const bargeIn = useCallback(
    async (task) => {
      try {
        setSupervisorCall(true)
        logger.debug("Barging-in on task", task)
        const answeredCall = parseTaskCallData(task, userData?.userId, "BARGE")
        if (answeredCall.interaction.mediaServer === "NMS") {
          const { data } = await dataService.Interactions.barge({
            agentId: userData?.userId,
            taskId: answeredCall.taskId,
            languageId: answeredCall.language.id,
            interactionId: answeredCall.interaction.id,
          })
          logger.debug("Barge request succeeded", data)
          setNmsParticipantId(data?.participantId)
        }
        setPresence(IN_QA_MEETING)
        initializeCallScreen()
        setAnsweredCall(answeredCall)
      } catch (err) {
        setSupervisorCall(null)
        logger.error("An error has occurred while attempting to barge in to task", err)
        showErrorToast("An error has occurred while attempting to barge in to task.  Refresh the list and try again", {
          autoClose: false,
        })
      }
    },
    [initializeCallScreen, setAnsweredCall, userData?.userId, setPresence, showErrorToast]
  )

  const steal = useCallback(
    async (task) => {
      try {
        setSupervisorCall(true)
        expectedInteractionIdRef.current = task.interactionId
        setExpectingAnswer(true)
        initializeCallScreen()
        await setPresence(AVAILABLE)
        await dataService.Calls.selfAssign({ taskId: task.id })
        logger.debug(`Call with task ID ${task.id} has been self-assigned`)
      } catch (err) {
        setSupervisorCall(null)
        logger.error("Unable to self-assign call", err)
        showWarnToast("Something went wrong while self-assigning your call.")
      }
    },
    [initializeCallScreen, setPresence, setExpectingAnswer, showWarnToast]
  )

  const wrapUp = useCallback(
    async ({ terminate = true, navigateTo = null } = {}) => {
      setExitConfirmation(true)
      if (terminate && taskBelongsToMe) {
        await callManager.closeCall(answeredCall?.taskId, presenceAfterCall)
      }
      if (supervisorCall) {
        setPresence(ON_BREAK)
      }
      setAnsweredCall(null)
      setNmsParticipantId(null)
      setDisconnected(false)
      expectedInteractionIdRef.current = null
      setHasAVIssues(false)
      setPresenceAfterCall(null)
      setSupervisorCall(null)
      setDialHistory([])

      if (navigateTo) {
        setNavigate(navigateTo)
      }
      setExpectingAnswer(false)
    },
    [
      taskBelongsToMe,
      setAnsweredCall,
      setHasAVIssues,
      setPresence,
      setExpectingAnswer,
      setNavigate,
      answeredCall?.taskId,
      presenceAfterCall,
      setPresenceAfterCall,
      supervisorCall,
      setDialHistory,
    ]
  )

  useEffect(() => {
    if (exitConfirmation) {
      setCallerHungUp(false)
    }
  }, [exitConfirmation, callerHungUp])

  //TODO multi-call: roll-back after multi-call is resolved
  useCallNotifications({ ringingCall, answeredCall, onAccept })
  const { initialCallStatusChecked } = useInitialCallStatus({
    userId: userData?.userId,
    handleRingingCall,
    setAnsweredCall,
    initializeCallScreen,
    answeredCall,
    SSEopen,
    setNmsParticipantId,
  })
  useUpdateDetailsOnRing({ ringingCall, setRingingCall })
  const { abort: abortAnswer } = useCallConnecting({
    expectingAnswer,
    exitConfirmation,
    callEnded: callerHungUp,
    answeredCall,
    wrapUp,
  })
  const {
    startWebRTC,
    stopWebRTC,
    restartWebRTC,
    webRTCerror,
    setCam,
    setMic,
    cam,
    mic,
    sendDTMFTone,
    endParticipant,
    holdParticipant,
    unHoldParticipant,
    reconnecting,
  } = usePlayer({
    setDisconnected,
    setExpectingAnswer,
    setNmsParticipantId,
    abortAnswer,
    userData,
    answeredCall,
    isAuthenticated,
  })
  const {
    participants,
    fetch: fetchParticipants,
    fetching: fetchingParticipants,
  } = useCallParticipants({ interactionId: answeredCall?.interaction?.id, webRTCerror })

  useEffect(() => {
    if (SSEopen && !subscribedRef.current) {
      SSE.on("ringing", handleNewSSECall)
      SSE.on("answered", handleAnsweredCall)
      SSE.on("endcall", handleCallDropped)
      SSE.on("multicall", handleNewSSEMultiCall) //TODO multi-call: remove after multi-call is resolved
      subscribedRef.current = true
    } else if (subscribedRef.current && !isAuthenticated) {
      subscribedRef.current = false
      setRingingCall(null)
      setAnsweredCall(null)
    }

    return () => {
      SSE.off("ringing", handleNewSSECall)
      SSE.off("answered", handleAnsweredCall)
      SSE.off("endcall", handleCallDropped)
      SSE.off("multicall", handleNewSSEMultiCall) //TODO multi-call: remove after multi-call is resolved
    }
  }, [
    SSEopen,
    isAuthenticated,
    handleNewSSECall,
    handleNewSSEMultiCall,
    handleAnsweredCall,
    handleCallDropped,
    setAnsweredCall,
    setRingingCall,
  ])

  useEffect(() => {
    if (exitConfirmation) {
      stopWebRTC()
    }
  }, [exitConfirmation, stopWebRTC])

  useEffect(() => {
    if (!answerFailedToast || expectingAnswer) {
      return
    }
    answerFailedToast.dismiss()
    setAnswerFailedToast(null)
  }, [answerFailedToast, expectingAnswer])

  useEffect(() => {
    callManager.setConfigService(config)
    callManager.on("closeCall", _closeCall)
    callManager.on("answerCall", acceptCall)
    callManager.on("warning", logger.warn.bind(logger))
    callManager.on("warning", showWarnToast)
    return () => {
      callManager.close()
    }
  }, [_closeCall, acceptCall, showWarnToast])

  return (
    <CallContext.Provider
      value={{
        ringingCall,
        answeredCall,
        exitConfirmation,
        disconnected,
        expectingAnswer,
        participants,
        fetchParticipants,
        fetchingParticipants,
        callReported,
        callReporting,
        webRTCerror,
        cam,
        mic,
        wrapUp,
        report,
        startWebRTC,
        stopWebRTC,
        restartWebRTC,
        listenIn,
        bargeIn,
        steal,
        setCam,
        setMic,
        sendDTMFTone,
        endParticipant,
        holdParticipant,
        unHoldParticipant,
        reconnecting,
        taskBelongsToMe,
        supervisorCall,
        initialCallStatusChecked,
      }}
    >
      <CallFormDataProvider>
        <CallSegmentsDataProvider>
          <CallHandlingDataProvider>{children}</CallHandlingDataProvider>
        </CallSegmentsDataProvider>
      </CallFormDataProvider>
    </CallContext.Provider>
  )
}

export const CallProvider = memo(_CallProvider)

export const useCall = createContextHook(CallContext, "useCall must be used within a CallProvider")

export default CallContext
