"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useConfigFlag = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = require("react");
var _FeatureBangContext = require("../contexts/FeatureBangContext");
var useConfigFlag = exports.useConfigFlag = function useConfigFlag(path, defaultValue) {
  var _useFeatureBang = (0, _FeatureBangContext.useFeatureBang)(),
    config = _useFeatureBang.config;
  var _useState = (0, _react.useState)(config.get(path, defaultValue)),
    _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
    _config = _useState2[0],
    setConfig = _useState2[1];
  (0, _react.useEffect)(function () {
    var unsubscribe = config.on("change.".concat(path), function (_ref) {
      var value = _ref.value;
      setConfig(value);
    });
    return function () {
      unsubscribe();
    };
  }, [path]);
  return _config;
};