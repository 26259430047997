// Here we are concerned with the Inhouse Call Segment notes field
const toLoggableCallSegment = (callSegment = {}) => {
  // eslint-disable-next-line no-unused-vars
  const { notes, ...remainingFields } = callSegment;

  return remainingFields;
}

// These fields are all currently on the Interaction object
const toLoggableCallSheet = (callSheet = {}) => {
  // These fields are PHI and should not end up in logs
  const {
    /* eslint-disable no-unused-vars */
    patientFirstName,
    patientLastName,
    patientMRN,
    patientDOB,
    providerFirstName,
    providerLastName,
    callerFirstName,
    callerLastName,
    notes,
    supervisorNotes,
    parsedNotes, // this field is added in martti-agent-api
    /* eslint-enable no-unused-vars */
    ...remainingFields
  } = callSheet;

  return remainingFields;
}

module.exports = {
  toLoggableCallSegment,
  toLoggableCallSheet
}
