import { reactLikeState } from "store/utils"
import { logger } from "utils/logger"

const { NON_BILLABLE_OPTIONS } = require("contexts/Call/Form/constants")

const initialState = {
  interactionId: "",
  interactionName: "",
  department: null,
  departmentName: "",
  language: "",
  nonBillableReasons: NON_BILLABLE_OPTIONS.NONE.key,
  unitId: "",
  hospitalId: "",
  hospitalName: "",
  patientDOB: null,
  patientMRN: undefined,
  patientLastName: "",
  patientFirstName: "",
  providerFirstName: "",
  providerLastName: "",
  phoneNumberAC: "",
  bchOaklandHospitalConfirmation: false,
  location: "",
  memberIdNumber: "",
  nyuAccessCode: "",
  sentaraAccessCode: "",
  visitId: "",
  ivrAccessCode: "",
  customerPhoneNumber: "",
}

const createCallFormSlice = (set) => {
  const setStateForKey = (key, valueOrFunction) => {
    reactLikeState(set, ["form", key])(valueOrFunction)
  }
  const setState = (formOverride) =>
    set((state) => {
      logger.debug("DM-814: Setting form state (CallFormSlice)", {
        currentDepartment: state.form.department,
        overrideDepartment: formOverride.department,
      })
      return {
        ...state,
        form: {
          ...state.form,
          ...formOverride,
          department: formOverride.department || state.form.department,
        },
      }
    })

  return {
    form: initialState,
    setState,
    setStateForKey,
    reset: () => {
      set((state) => {
        return {
          ...state,
          form: initialState,
        }
      })
    },
  }
}

export default createCallFormSlice
