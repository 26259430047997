import { createWithEqualityFn } from 'zustand/traditional'
import { shallow } from "zustand/shallow"
import createCallScreenSupportSlice from "./slices/CallScreenSupportSlice"
import createAuthSlice from "./slices/AuthSlice"

const useStore = createWithEqualityFn((set, get) => ({
  ...createAuthSlice(set, get),
  ...createCallScreenSupportSlice(set, get),
}), shallow)

export default useStore
