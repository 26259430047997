const { DataServiceEndPoint } = require("@cloudbreakus/network-utilities")

class SSE extends DataServiceEndPoint {
  static key = "SSE"

  subscribe = (payload) => {
    const useOverride = this._config.get("sse.v2.useAlternativeNotificationUrl")
    const url = useOverride
      ? `${this.url}/v1/subscriptions/v2`
      : `${this._config.get("sse.v2.notificationUrl")}/subscriptions/v2`
    return this._httpService.put(url, payload, {
      headers: { Authorization: `bearer ${this.token}` },
    })
  }

  unsubscribe = (data) => {
    const useOverride = this._config.get("sse.v2.useAlternativeNotificationUrl")
    const url = useOverride
      ? `${this.url}/v1/subscriptions/v2`
      : `${this._config.get("sse.v2.notificationUrl")}/subscriptions/v2`
    return this._httpService.delete(url, {
      headers: { Authorization: `bearer ${this.token}` },
      data,
    })
  }
}

module.exports = SSE
