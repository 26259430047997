const { noop } = require("lodash")
const { clientEventNames, serverEventNamesV2 } = require("../constants")
const { serverToClient: translate } = require("./translate")

const ProcessingManager = require("./DataProcessing")
const MessageProcessorChild = require("./MessageProcessor/MessageProcessor")
const { toLoggableCallSegment } = require('../../utils/redaction')

class MessageProcessor {
  _logger = null
  _emit = noop
  _onOpen = noop
  _onKeepAlive = noop
  _onSubscribe = noop
  _onUnsubscribe = noop

  constructor({ logger, configService, onEmit = noop, onOpen = noop, onKeepAlive = noop, onSubscribe = noop, onUnsubscribe = noop } = {}) {
    this._logger = logger
    this._emit = onEmit
    this._onOpen = onOpen
    this._onKeepAlive = onKeepAlive
    this._onSubscribe = onSubscribe
    this._onUnsubscribe = onUnsubscribe

    this._processingManager = new ProcessingManager({ logger })
    this._messageProcessor = new MessageProcessorChild({ logger, processing: this._processingManager, configService })
    this._processingManager?.initialize()
  }

  async process(messageEvent) {
    const eventId = messageEvent.lastEventId
    const serverPayload = typeof(messageEvent.data) === "object" ?  messageEvent.data.payload : messageEvent.data
    const processingFunction = this._selectProcessingFunction(eventId)
    if (processingFunction) {
      return processingFunction(serverPayload, eventId)
    }
    const [clientEvent, clientPayload] = await this._messageProcessor.process(eventId, serverPayload)
    this._logEvent(serverPayload, eventId)
    if (!clientEvent) {
      return
    }
    this._emit(clientEvent, clientPayload)
  }

  _selectProcessingFunction(eventId) {
    return {
      [serverEventNamesV2.NEW_EVENT_SOURCE]: this._onOpen,
      [serverEventNamesV2.KEEP_ALIVE_MESSAGE]: this._onKeepAlive,
      [serverEventNamesV2.NEW_SUBSCRIPTION]: this._onSubscribe,
      [serverEventNamesV2.EXPIRED_SUBSCRIPTION]: this._onUnsubscribe,
      [serverEventNamesV2.INTERACTION_PARTICIPANTS]: this._onCallParticipantsChange.bind(this),
    }[eventId]
  }


  _onCallRing(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    const clientPayload = translate.task(serverPayload.tasks[0])
    this._emit(clientEventNames.RINGING, clientPayload)
  }

  _onCallAnswer(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    const clientPayload = translate.task(serverPayload.tasks[0])
    this._emit(clientEventNames.ANSWERED, clientPayload)
  }

  _onCallEnd(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    this._emit(clientEventNames.END_CALL, null)
  }

  _onPresenceChange(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    const clientPayload = translate.presence(serverPayload)
    this._emit(clientEventNames.STATUS, clientPayload)
  }

  _onProfileChange(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    this._emit(clientEventNames.PROFILE, serverPayload)
  }

  _onCallSegmentsChange(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    const clientPayload = serverPayload?.callSegments?.map(translate.callSegment)
    this._emit(clientEventNames.CALL_SEGMENTS, clientPayload)
  }

  _onCallParticipantsChange(serverPayload, eventId) {
    this._logEvent(serverPayload, eventId)
    const clientPayload = serverPayload?.map(translate.callParticipant)
    this._emit(clientEventNames.CALL_PARTICIPANTS, clientPayload)
  }

  _onUnknownMessage(serverPayload, eventId) {
    this._logger?.()?.warn?.(`SSEv2: Message ${eventId} unknown, payload disregarded`, serverPayload)
  }

  _logEvent(serverPayload, eventId) {
    if (eventId === serverEventNamesV2.INTERACTION_CALL_SEGMENTS && serverPayload) {
      // Avoid accidental logging of PHI in call segments
      serverPayload = { ...serverPayload, callSegments: serverPayload?.callSegments?.map(toLoggableCallSegment) }
    }
    this._logger?.()?.debug(`SSEv2: ${eventId} triggered with payload`, serverPayload)
  }
}

module.exports = MessageProcessor
