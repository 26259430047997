import React from "react"
import { useCall } from "contexts/Call"
import { useAlertMessage } from "hooks/useAlertMessage"
import { isEmpty, isEqual } from "lodash"
import { createContext, memo, useCallback, useEffect, useMemo, useState } from "react"
import { createContextHook } from "utils/contextHelpers"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"
import { toLoggableCallSheet } from "martti-agent-services"
import { NON_BILLABLE_OPTIONS } from "./constants"

import { useDebouncedForm } from "hooks/useDebouncedForm"
import { useAccessCode } from "./hooks/useAccessCode"
import { useCallFormFields } from "./hooks/useCallFormFields"
import { useCustomFields } from "./hooks/useCustomFields"
import { useDepartmentsForForm } from "./hooks/useDepartmentsForForm"
import { usePartnerAccessCode } from "hooks/usePartnerAccessCode"
import { callFormDataManager } from "./CallFormDataManager"
import { usePureCallback } from "hooks/usePureCallback"
const { HttpErrors } = require("@cloudbreakus/network-utilities")

const logger = getLoggerInstance()

const CallFormDataContext = createContext()

export const _showSpinner = (bouncing, refreshing, saved) => bouncing || refreshing || !saved

const _CallFormDataProvider = ({ children }) => {
  const { answeredCall, exitConfirmation } = useCall()
  const { showErrorToast } = useAlertMessage()

  const [serverState, setServerState] = useState({})
  const [changeDetected, setChangeDetected] = useState(false)
  const [validatedAccessCode, setValidatedAccessCode] = useState(false)
  const [refreshing, setRefreshing] = useState(false)

  const {
    initialFetch,
    refreshCallSheet,

    interactionId,
    interactionName,
    department,
    departmentName,
    language,
    nonBillableReasons,
    unitId,
    hospitalId,
    hospitalName,
    patientFirstName,
    patientLastName,
    patientMRN,
    patientDOB,
    providerFirstName,
    providerLastName,
    setDepartment,
    setNonBillableReasons,
    setPatientFirstName,
    setPatientLastName,
    setPatientMRN,
    setPatientDOB,
    setProviderFirstName,
    setProviderLastName,

    phoneNumberAC,
    bchOaklandHospitalConfirmation,
    location,
    memberIdNumber,
    nyuAccessCode,
    sentaraAccessCode,
    visitId,
    callerFirstName,
    callerLastName,
    setVisitId,
    setSentaraAccessCode,
    setNyuAccessCode: setNYUAccessCode,
    setMemberIdNumber,
    setLocation,
    setPhoneNumberAC,
    setBchOaklandHospitalConfirmation: setBCHOaklandHospitalConfirmation,
    setCallerFirstName,
    setCallerLastName,

    customerPhoneNumber,
    ivrAccessCode,
  } = useCallFormFields(answeredCall, setServerState)

  const { accessCodeObject, loadingAccessCode, getPartnerAccessCode } = usePartnerAccessCode(
    hospitalId,
    validatedAccessCode
  )

  const { accessCode: customFieldsData, departmentId } = useCustomFields(unitId, accessCodeObject)

  const { departmentOptions } = useDepartmentsForForm(
    setDepartment,
    setServerState,
    answeredCall?.interaction?.hospitalId || hospitalId,
    departmentId,
    answeredCall,
  )

  const accessCodeValidationData = useAccessCode({
    validatedAccessCode,
    setValidatedAccessCode,
    ivrAccessCode,
    unitId,
    answeredCall,
    interactionId,
    accessCodeObject,
    loadingAccessCode,
    getPartnerAccessCode,
    refreshCallSheet,
  })

  const { bouncing, onDebouncedChange } = useDebouncedForm(1500, setChangeDetected)

  const clientState = useMemo(
    () => ({
      nonBillableReason: nonBillableReasons === NON_BILLABLE_OPTIONS.NONE.key ? null : nonBillableReasons,
      departmentId: department,
      patientFirstName,
      patientLastName,
      patientMRN,
      patientDOB,
      phoneNumberAC,
      providerFirstName,
      providerLastName,
      bchOaklandHospitalConfirmation,
      location,
      memberIdNumber,
      nyuAccessCode,
      sentaraAccessCode,
      visitId,
      callerFirstName,
      callerLastName,
    }),
    [
      nonBillableReasons,
      department,
      patientFirstName,
      patientLastName,
      patientMRN,
      patientDOB,
      phoneNumberAC,
      providerFirstName,
      providerLastName,
      bchOaklandHospitalConfirmation,
      location,
      memberIdNumber,
      nyuAccessCode,
      sentaraAccessCode,
      visitId,
      callerFirstName,
      callerLastName,
    ]
  )

  const saved = useMemo(
    () => (isEmpty(serverState) ? true : isEqual(serverState, clientState)),
    [serverState, clientState]
  )

  const saveCallSheet = useCallback(
    (payload) => {
      return dataService.Interactions.CallSheet.set({
        interactionId,
        payload,
      })
    },
    [interactionId]
  )

  const submitCallFormData = useCallback(async () => {
    let payload
    try {
      payload = {
        nonBillableReason: nonBillableReasons === NON_BILLABLE_OPTIONS.NONE.key ? null : nonBillableReasons,
        departmentId: department,
        patientFirstName,
        patientLastName,
        patientMRN,
        patientDOB,
        phoneNumberAC,
        providerFirstName,
        providerLastName,
        bchOaklandHospitalConfirmation,
        location,
        memberIdNumber,
        nyuAccessCode,
        sentaraAccessCode,
        visitId,
        callerFirstName,
        callerLastName,
      }
      await saveCallSheet(payload)
      setServerState(payload)
      logger.info(`Call sheet updated`, toLoggableCallSheet(payload))
    } catch (err) {
      if (!(err instanceof HttpErrors.ThrottledNetworkError)) {
        showErrorToast("Call sheet failed to update, please try again.")
      }
      logger.error(`Call sheet failed to update`, err, toLoggableCallSheet(payload))
    }
    callFormDataManager.onSubmitted()
  }, [
    nonBillableReasons,
    department,
    patientFirstName,
    patientLastName,
    patientMRN,
    patientDOB,
    phoneNumberAC,
    providerFirstName,
    providerLastName,
    bchOaklandHospitalConfirmation,
    location,
    memberIdNumber,
    nyuAccessCode,
    sentaraAccessCode,
    visitId,
    callerFirstName,
    callerLastName,
    saveCallSheet,
    showErrorToast,
  ])

  const onNonBillableReasons = onDebouncedChange(setNonBillableReasons)
  const onDepartment = onDebouncedChange((...args) => {
    logger.debug("DM-814 - manual value selection of department triggered", args)
    setDepartment(...args)
  })
  const onPatientFirstName = onDebouncedChange(setPatientFirstName)
  const onPatientLastName = onDebouncedChange(setPatientLastName)
  const onPatientMRN = onDebouncedChange(setPatientMRN)
  const onProviderFirstName = onDebouncedChange(setProviderFirstName)
  const onProviderLastName = onDebouncedChange(setProviderLastName)

  const onVisitId = onDebouncedChange(setVisitId)
  const onSentaraAccessCode = onDebouncedChange(setSentaraAccessCode)
  const onNYUAccessCode = onDebouncedChange(setNYUAccessCode)
  const onMemberIdNumber = onDebouncedChange(setMemberIdNumber)
  const onLocation = onDebouncedChange(setLocation)
  const onPhoneNumberAccessCode = onDebouncedChange(setPhoneNumberAC)
  const onBCHOaklandHospital = onDebouncedChange(setBCHOaklandHospitalConfirmation)
  const onCallerFirstName = onDebouncedChange(setCallerFirstName)
  const onCallerLastName = onDebouncedChange(setCallerLastName)

  const onPatientDOB = useMemo(() => {
    return onDebouncedChange(setPatientDOB)
  }, [onDebouncedChange, setPatientDOB])

  useEffect(() => {
    if (!interactionId || !changeDetected) {
      return
    }
    callFormDataManager.detectChange()
    const doSubmit = exitConfirmation ? true : !bouncing
    if (!doSubmit) {
      return
    }
    setChangeDetected(false)
    callFormDataManager.submit()
  }, [interactionId, bouncing, changeDetected, exitConfirmation])

  const triggerSubmit = usePureCallback(submitCallFormData)
  const triggerRefresh = usePureCallback(refreshCallSheet)

  useEffect(() => {
    callFormDataManager.on("submit", triggerSubmit)
    callFormDataManager.on("refresh", triggerRefresh)
    callFormDataManager.on("running", setRefreshing)

    return () => {
      callFormDataManager.off("submit", triggerSubmit)
      callFormDataManager.off("refresh", triggerRefresh)
      callFormDataManager.off("running", setRefreshing)
    }
  }, [triggerSubmit, triggerRefresh])

  const _refreshCallSheet = useCallback(() => {
    callFormDataManager.refresh()
  }, [])

  useEffect(() => {
    if (!interactionId) {
      return
    }
    return () => callFormDataManager.wrapUp()
  }, [interactionId])

  const showSpinner = useMemo(() => _showSpinner(bouncing, refreshing, saved), [saved, refreshing, bouncing])

  return (
    <CallFormDataContext.Provider
      value={{
        ...accessCodeValidationData,
        ...customFieldsData,
        interactionId: interactionName,
        language,
        unitId,
        hospitalName,
        department,
        departmentName,
        patientFirstName,
        patientLastName,
        patientMRN,
        patientDOB,
        providerFirstName,
        providerLastName,
        departmentOptions,
        nonBillableReasons,

        onPatientDOB,
        onNonBillableReasons,
        onDepartment,
        onPatientFirstName,
        onPatientLastName,
        onPatientMRN,
        onProviderFirstName,
        onProviderLastName,
        refreshCallSheet: _refreshCallSheet,
        initialFetch,
        saved: !showSpinner,
        phoneNumberAC,
        bchOaklandHospitalConfirmation,
        location,
        memberIdNumber,
        nyuAccessCode,
        sentaraAccessCode,
        visitId,
        callerFirstName,
        callerLastName,
        onVisitId,
        onSentaraAccessCode,
        onNYUAccessCode,
        onMemberIdNumber,
        onLocation,
        onPhoneNumberAccessCode,
        onBCHOaklandHospital,
        onCallerFirstName,
        onCallerLastName,

        customerPhoneNumber,
        ivrAccessCode,
      }}
    >
      {children}
    </CallFormDataContext.Provider>
  )
}

export const CallFormDataProvider = memo(_CallFormDataProvider)

export const useCallFormData = createContextHook(
  CallFormDataContext,
  "useCallFormData must be used within a CallFormDataContext"
)

export default CallFormDataContext
