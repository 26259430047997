import { useCallback, useEffect, useState } from "react"
import { dataService } from "utils/dataService"
import { useAuth } from "contexts/Auth"
import { getLoggerInstance } from "utils/logger"
import { toLoggableCallSheet } from "martti-agent-services"
import { callFormDataManager } from "../CallFormDataManager"

const logger = getLoggerInstance()

export const useCallSheetData = (interactionId) => {
  const [callSheetData, setCallSheetData] = useState(null)
  const { isAuthenticated } = useAuth()

  const getCallSheet = useCallback(async (interactionId) => {
    try {
      const response = await dataService.Interactions.CallSheet.get({
        interactionId,
      })
      const callSheetData = response?.data
      callFormDataManager.onRefreshed()
      if (callFormDataManager.changeDetected) {
        logger.warn("Successfully retrieved call sheet data but user currently typing/submitting so data discarded", toLoggableCallSheet(callSheetData))
        return callSheetData
      }
      logger.debug("Successfully retrieved call sheet data:", toLoggableCallSheet(callSheetData))
      setCallSheetData(callSheetData)
      return callSheetData
    } catch (err) {
      logger.error("An error occurred while fetching call sheet data", err)
      callFormDataManager.onRefreshed()
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated && interactionId) {
      getCallSheet(interactionId)
    } else {
      setCallSheetData(null)
    }
  }, [interactionId, getCallSheet, isAuthenticated])

  const getCallSheetData = useCallback(() => {
    return getCallSheet(interactionId)
  }, [getCallSheet, interactionId])

  return {
    callSheetData,
    getCallSheetData,
  }
}
