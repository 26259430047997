import { useState, useEffect, useCallback } from "react"
import now from "dayjs"
import { SSE } from "utils/SSE"
import { ACTIVE, COMPLETED } from "constants/statuses"
import { useAuth } from "contexts/Auth"
import { dataService } from "utils/dataService"
import { getLoggerInstance } from "utils/logger"
import { useAlertMessage } from "hooks/useAlertMessage"
import { HttpErrors } from "@cloudbreakus/network-utilities"

const logger = getLoggerInstance()

const capitalizeFirstLetter = (string) => {
  return string?.charAt?.(0).toUpperCase() + string?.slice(1)
}

const distillCalls = (calls) => {
  return calls.map((c) => ({
    timestamp: c.acceptTime,
    interaction: c.interactionName,
    duration: Math.trunc((Date.parse(c.completeTime) - Date.parse(c.acceptTime)) / 1000),
    customer: c.interactionPartnerFriendlyName,
    unitId: c.interactionMarttiName,
    skill: c.queueName,
    callType: capitalizeFirstLetter(c?.interactionType?.toLowerCase()),
  }))
}

const sixDaysAgo = now().subtract(6, "days").toJSON()

export const useCallHistory = ({ from = sixDaysAgo, order = "acceptTime" } = {}) => {
  const { userData, SSEopen } = useAuth()
  const [calls, setCalls] = useState([])
  const [error, setError] = useState()
  const { showCustomToast } = useAlertMessage()

  const getCalls = useCallback(async () => {
    if (!userData?.userId) {
      return
    }
    try {
      const callData = await dataService.Calls.get({
        agentId: userData.userId,
        status: [COMPLETED, ACTIVE],
        from,
        order,
      })
      logger.debug("Recent calls retrieved:", callData.data)
      setCalls(distillCalls(callData.data))
    } catch (err) {
      if (!(err instanceof HttpErrors.ThrottledNetworkError)) {
        showCustomToast({
          message: "An error occurred while fetching recent calls.",
          level: "error",
          onRetry: getCalls,
          closeable: true,
        })
      }
      logger.error("An error occurred while fetching recent calls.", err)
      setError(err)
    }
  }, [from, order, userData?.userId, showCustomToast])

  useEffect(() => {
    if (!SSEopen) {
      return
    }
    getCalls()
    SSE.on("endcall", getCalls)
    return () => {
      SSE.off("endcall", getCalls)
      setCalls([])
    }
  }, [getCalls, SSEopen])

  return {
    getCalls,
    calls,
    error,
  }
}

export default useCallHistory
